<template lang="pug">
form(@submit="onSubmit" @keydown.esc="onCancel")
    template( v-if="ready")
      .modal-header
        h2
          | {{translate('admin.product.edit.change_stock_amount' )}}
        .icon-delete(@click="closeModal")

      .modal-content
        div(v-for="stock_entry in warehouse_entries")
          .title
            | {{ stock_entry.name }}
          stock_quantity.quantity(:idx="0", :variant="buildVariant(stock_entry)", :no_save="true")
          | {{warehouse_entries}}

      .modal-footer
        .left.buttons
          .button.secondary.inverted.short( @click="closeModal")
            | {{translate('admin.buttons.cancel')}}
        .right
          button.button.primary.short( type="submit" @click="onSubmit")
            | {{translate('admin.buttons.save')}}
</template>

<script>
import Root from '../../../mixins/root_computed.js'
import stock_quantity from '../../variants/attribute/stock-quantity.vue'
export default{
  mixins: [Root],
  components: {stock_quantity},
  data(){
    return {
      warehouse_entries: {},
      onDone: null,
      ready: false,
      variants: {}
    }
  },

  methods: {
    data_synced(){ this.ready = true},
    buildVariant(warehouse){
      if (!this.variants[warehouse.id]) this.$set(this.variants, warehouse.id, {stock_amounts: [{amount: 0}], booked: 0})
      return this.variants[warehouse.id]
    },

    onSubmit(e){
      if(e) e.preventDefault()
      let result = Object.keys(this.warehouse_entries).map((key) => {
        return {
          amount: this.variants[key].stock_amounts[0].amount,
          warehouse: key,
        }
      })
      console.log(result)
      if(this.onDone) this.onDone(result, ()=>{this.closeModal()})
    }
  }
}
</script>