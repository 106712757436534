<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .modal-header
      h2
        | {{ translate('admin.settings.edit.edit_contact_information' ) }}
      .icon-delete( @click="onCancel")

    .modal-content
      div( v-if="countries_ready")
        .invalid.warning( v-if="!valid")
          | {{ translate('admin.settings.address.invalid' ) }}

        .field
          label.label
            | {{ translate('admin.settings.address.account_type' ) }}
          select.fancy-select-2( v-model="settings.address.account_type")
            option( value="personal")
              | {{ translate('admin.settings.address.account_types.personal' ) }}
            option( value="company")
              | {{ translate('admin.settings.address.account_types.business' ) }}
          helpers-validation-message.error(:errors="validation" field="settings.address.account_type")

        .field( v-if="settings.address.account_type === 'company'")
          helpers-label.label(field="settings.address.company")
          input( name="settings.company" v-model="settings.address.company" :class="{invalid: isInvalid('settings.address.company')}")
          helpers-validation-message.error(:errors="validation" field="settings.address.company")

        .field( v-if="settings.address.account_type === 'company'")
          helpers-label.label(field="settings.address.registration_number")
          input( v-model="settings.address.registration_number" :class="{invalid: isInvalid('settings.address.registration_number')}" :placeholder="translate('activerecord.attributes.settings.registration_number_placeholder')")
          helpers-validation-message.error(:errors="validation" field="settings.address.registration_number")

        .field( v-if="settings.address.account_type === 'company'")
          helpers-label.label(field="settings.address.vat_number")
          input( v-model="settings.address.vat_number" :class="{invalid: isInvalid('settings.address.vat_number')}" :placeholder="translate('activerecord.attributes.settings.vat_number_placeholder')")
          helpers-validation-message.error(:errors="validation" field="settings.address.vat_number")

        .field
          helpers-label.label(field="settings.address.address1")
          input( name="settings.address1" v-model="settings.address.address1" :class="{invalid: isInvalid('settings.address.address1')}")
          helpers-validation-message.error(:errors="validation" field="settings.address.address1")
          .additional-address( @click="additional_address=!additional_address" v-if="!additional_address")
            | {{ translate('admin.shared.add_address_line' ) }}

        .field( v-if="additional_address")
          helpers-label.label(field="settings.address.address2")
          input( name="settings.address2" v-model="settings.address.address2" :class="{invalid: isInvalid('settings.address.address2')}")
          helpers-validation-message.error(:errors="validation" field="settings.address.address2")

        .field
          helpers-label.label(field="settings.address.zip")
          input( name="settings.zip" v-model="settings.address.zip" :class="{invalid: isInvalid('settings.address.zip')}")
          helpers-validation-message.error(:errors="validation" field="settings.address.zip")

        .field.clearfix
          helpers-label.label(field="settings.address.country_id")
          select.fancy-select-2( v-model="settings.address.country_id")
            option( v-for="option in country_options()" v-text="option.text" :value="option.value")
          helpers-validation-message.error(:errors="validation" field="settings.address.country_id")

        .field.clearfix
          helpers-label(field="settings.address.state_id")
          select.fancy-select-2( v-model="settings.address.state_id")
            option( v-for="option in state_options()" v-text="option.text" :value="option.value")
          helpers-validation-message.error(:errors="validation" field="settings.address.state_id")

        .field
          helpers-label.label(field="settings.address.city")
          input( name="settings.city" v-model="settings.address.city" :class="{invalid: isInvalid('settings.address.city')}")
          helpers-validation-message.error(:errors="validation" field="settings.address.city")

        .field
          helpers-label.label(field="settings.address.phone")
          input( name="settings.phone" v-model="settings.address.phone" :class="{invalid: isInvalid('settings.address.phone')}")
          helpers-validation-message.error(:errors="validation" field="settings.address.phone")

    .modal-footer
      .left
        button.v3-button.secondary.inverted.short( @click="onCancel")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.v3-button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.buttons.save')}}
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  data() {
    return {
      additional_address: null,
      countries: [],
      countries_ready: null,
      settings: { address: { country_id: null, state_id: null }, registration_number: null },
      current_country: {},
      render_states: null,
      all_states: {},
      valid: true
    };
  },

  validations: {
    "settings.address.city":       "required, maxLength: 255",
    "settings.address.company":             "function: val_company",
    "settings.address.registration_number": "function: val_company",
    "settings.address.phone":      "required, maxLength: 255",
    "settings.address.address1":   "required, maxLength: 255",
    "settings.address.zip":        "required, maxLength: 255",
    "settings.address.country_id": "required"
  },

  created() {
    return this.if_authenticated().then(() => {
      return this.fetch_cached_collection('countries').then(() => {
        this.countries_ready = true;
        if (this.settings.address.account_type == null) { this.settings.address.account_type = "company"; }
        this.$watch("settings.address.country_id", this.clearState);
        return this.$watch("settings.address.country_id", this.fetchStates, {immediate: true});
      });
    });
  },

  methods: {
    clearState() { if (this.settings.address) { return this.settings.address.state_id = null; } },

    fetchStates() {
      const id = parseInt(this.settings.address.country_id);
      this.current_country = this.filter(this.countries, country => country.id === id)[0];
      this.render_states = false;

      if (this.current_country && this.current_country.has_states) {
        if (this.all_states[id] === undefined) {
          return this.inJson(this.api_request("GET", "states", {where: `country_id:${id}`})).then(states => {
            this.$set(this.all_states, id, states);
            this.set_default_state(id);
            return this.render_states = true;
          });
        } else {
          this.set_default_state(id);
          return this.render_states = true;
        }
      }
    },

    set_default_state(country_id) {
      const states = this.all_states[country_id];
      const current = parseInt(this.settings.address?.state_id);
      if (!this.filter(states, state => state.id === current)[0]) {
        return this.settings.address.state_id = this.all_states[country_id][0]?.id;
      }
    },

    state_options() {
      return (this.all_states[this.current_country?.id] || []).map(state => ({
        text: state.name,
        value: state.id
      }));
    },

    country_options() {
      return (this.countries || []).map(country => ({
        text: country.name,
        value: country.id
      }));
    },

    val_company() {
      if (this.settings.address.account_type === "company") {
        return this.$sOptions.validators["required"].apply(this, arguments);
      } else { return true; }
    }
  }
}
</script>
