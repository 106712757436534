<template lang="pug">
div( style="width: 100%;")
    span(v-text="fullNumber(stock_entry.amount)")
    .grid-100(v-if="!show_add_items && !show_del_items")
      .button.secondary.inverted.shorter.smaller(@click="show_add_items=true,change_qty=0") {{translate('admin.product.edit.add_items')}}
      .button.secondary.inverted.shorter.smaller(@click="show_del_items=true,change_qty=0") {{translate('admin.product.edit.remove_items')}}
    .grid-50(v-if="show_add_items")
      input.quantity-changer(v-model.number="change_qty")
      .button.secondary.inverted.shorter.smaller(@click="show_add_items=false,save(change_qty)") {{translate('admin.buttons.add')}}
    .grid-50(v-if="show_del_items")
      input.quantity-changer(v-model.number="change_qty")
      .button.secondary.inverted.shorter.smaller(@click="show_del_items=false,save(-change_qty)") {{translate('admin.buttons.remove')}}
</template>

<script>
export default {
  props: ["idx", "variant", "no_save"],
  data() {
    return {
      entry: {amount: 0},
      show_add_items: false,
      show_del_items: false,
      change_qty: 0,
    }
  },
  methods: {
    save(amount){
      this.stock_entry.amount = parseFloat(this.stock_entry.amount) + amount
      if (this.no_save)     return
      if (!this.variant.id) return

      this.axios.patch(`/variants/${this.variant.id}`, {variant: this.variant })
    }
  },
  computed:{
    stock_entry(){
      return this.variant.stock_amounts[this.idx]
    },
  },
}
</script>