<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
        .modal-header
            h2
                | {{translate('admin.domains.edit.title' )}}
            .icon-delete( @click="closeModal")

        .modal-content
            .field
                helpers-label(field="domain.type")
                .field-container
                    .select.fancy-select( @click="openDropDownMenu")
                        .fake-input( data="fake-name")
                            | {{ translate('admin.domains.type.'+domain.type+'.title' ) }}
                        ul.custom-dropdown
                            li( v-for="domainType in types" @click="domain.type=domainType")
                                | {{ translate('admin.domains.type.'+domainType+'.title' ) }}
                helpers-validation-message.error(:errors="validation" field="domain.type")

            .field
                helpers-label(field="domain.name")
                .field-container
                    input( name="domain.name" v-model="domain.name" :class="{invalid: isInvalid('domain.name')}")
                helpers-validation-message.error(:errors="validation" field="domain.name")

            .field
                h4( v-text="translate('admin.domains.type.'+domain.type+'.title')")
                p( v-html="translate('admin.domains.type.'+domain.type+'.description')")


        .modal-footer
            .left
                .button.secondary.inverted.short( @click="closeModal")
                    | {{translate('admin.buttons.cancel')}}
            .right
                button.button.secondary.short( type="submit" @click="onSubmit")
                    | {{translate('admin.buttons.add')}}
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
export default{
    mixins: [Root],
    data() {
        return {
            domain: { name: '', type: 'Domain::Dns' },
            types: ['Domain::Ip','Domain::Dns']
        };
    },

    validations: {
        "domain.name": "required, maxLength: 255, function: validate_domain_name"
    },

    methods: {
        onSubmit(e) {
            e.preventDefault();
            if (!this.$validate()) { return; }

            if (this.domain.id) {
                return this.onUpdate(e);
            } else {
                return this.onCreate(e);
            }
        },

        onCreate(e) {
            return this.inJson(this.api_request("POST", "domains", {domain: this.domain})).then((response => {
              this.modal = null;
              this.$router.push(`/domains?ux=${new Date().getTime()}`);
          }
            ), resp => this.set_validation(this, "domain", resp));
        },
        onUpdate(e) {
            return this.api_request("PATCH", `domains/${this.domain.id}`, {domain: this.domain}).then((response => {
                this.modal = null;
            }
            ), resp => this.set_validation(this, "domain", resp));
        },

        onDestroy(e) {
            e.preventDefault();
            return this.api_request("PATCH", `domains/${this.domain.id}`, {domain: this.domain}).then(response => {
              this.modal = null;
              this.$router.push(`/domains?ux=${new Date().getTime()}`);
            });
        },

        onSetPrimary(e, domain) {
            return this.inJson(this.api_request("PATCH", `domains/${domain.id}/set_primary`)).then(response => {
              this.modal = null;
              this.$router.push(`/domains?ux=${new Date().getTime()}`);
            });
        },

        validate_domain_name() {
            if (this.domain.type === "Domain::Dns") {
                return (typeof this.domain.name === 'string') &&
                        (this.domain.name.split('.').length === 2) &&
                        !(this.domain.name.startsWith('www'));
            } else {
                return true;
            }
        }
    }
}
</script>
