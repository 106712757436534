<template lang="pug">
form(@submit="onSubmit" @keydown.esc="onCancel")
    template(v-if="ready")
      .modal-header
        h2
          | {{translate('admin.product.edit.change_price' )}}
        .icon-delete(@click="closeModal")

      .modal-content
        value_price(:variant="variant")

      .modal-footer
        .left.buttons
          .button.secondary.inverted.short(@click="closeModal")
            | {{translate('admin.buttons.cancel')}}
        .right
          button.button.primary.short(type="submit" @click="onSubmit")
            | {{translate('admin.buttons.save')}}
</template>

<script>
import Root from '../../../mixins/root_computed.js';
import value_price from '../../variants/attribute/value-price.vue';
export default{
  mixins: [Root],
  components: {value_price},
  data() {
    return {
      variant: {
        price: null
      },
      onDone: null,
      ready: false
    };
  },

  methods: {
    data_synced() { this.ready = true; },

    onSubmit(e) {
      e?.preventDefault();
      this.onDone?.(this.variant.price, () => this.closeModal());
    }
  }
}
</script>