/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import getSlug from 'speakingurl';
export default {
  data() {
    return {handle_was_changed: false};
  },

  created() {
    this.$watch(`${this.$options.model_klass}.name`, this.updateHandle, {deep: true});
    this.$watch(`${this.$options.model_klass}.translations.${this.$get1('app.data_language')}.name`, this.updateHandle, {deep: true});
    this.$watch("parentPermalink", this.updateHandleBecauseOfParent);

    this.$watch(`${this.$options.model_klass}.id`, (value=> { if (value) { return this.handle_was_changed = true; } }), {deep: true});
    this.$watch(`${this.$options.model_klass}.handle`, value=> {
      if (value?.length === 0) {
        this.handle_was_changed = false;
      } else {
        this.$nextTick(() => { if (!value?.endsWith("-")) { this.handle_model.handle= getSlug(value); } });
      }
    }
    , {deep: true});
  },

  computed: {
    handle_model() { return this[this.$options.model_klass]; }
  },
  methods: {
    stopGenHandle() {
      this.handle_was_changed = true;
    },

    updateHandleBecauseOfParent(value, old) {
      if (old) {
        return this.updateHandle(this.handle_model.handle.slice(getSlug(old).length + 1));
      } else {
        return this.updateHandle(this.handle_model.handle);
      }
    },

    updateHandle(value){
      if (!this.$get1('handle_was_changed') && !this.$get1(`${this.$options.model_klass}.id`)) {
        if (value) {
          const parented_value = [this.parentPermalink, value].filter(val => val).join("-");
          return this.handle_model.handle = getSlug(parented_value);
        }
      }
    },

    handlePath(){
      return `${this.$options.model_klass}.handle`;
    }
  }
}