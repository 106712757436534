/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
export default {
  created() {
    return this.if_authenticated().then(() => {
      return this.old_settings = JSON.parse(JSON.stringify(this.settings));
    });
  },

  methods: {
    onCancel(e) {
      e.preventDefault();
      for (var key in this.settings) { this.settings[key] = this.old_settings[key]; }
      this.closeModal();
    },

    onSubmit(e) {
      e.preventDefault();
      this.clean_all_validations(this);
      if (!this.$validate()) { return; }
      const request = this.inJson(this.api_request("PATCH", "/settings", {settings: this.settings}));
      request.then(settings => {
        Object.merge(this.current_shop, settings, {deep: true});
        this.settings = settings;
        return this.closeModal();
      });
      return request.catch(response => {
        const address = JSON.parse(response._body)?.address;
        if (address.errors?.data && !address.errors?.data.first_name && !address.errors?.data.last_name) {
          this.closeModal();
          this.modal = {
            component: "settings_contact",
            css_class: "admin-v2",
            data: {settings: this.settings, valid: false}
          };
        }
        return this.set_validation(this, 'settings', response);
      });
    }
  }
}