# Stolen and a bit changed
# from:
#
# vue-datepicker v0.1.2
# https://github.com/weifeiyue/vue-datepicker

<template lang="pug">
.mz-datepicker
  input( :value="localValue" readonly='true' :disabled="disabled" :class="{focus: show}" @click="click" @mousedown="$event.preventDefault()" :placeholder='placeholder')
  a( v-if="clearable && localValue" @click.stop="clear")
  div( class="mz-datepicker-popup" :class="{'mz-datepicker-popup-left':left}" v-show="show" transition="mz-datepicker-popup" tabindex="-1" @blur="show = false" @mousedown="$event.preventDefault()" @keyup.up="changeMonth(-1,1)" @keyup.down="changeMonth(1,1)" @keyup.left="changeYear(-1,1)" @keyup.right="changeYear(1,1)" ref='popup')
    div( :class="{'mz-calendar-range':range}")
      template( v-for="no in count")
        .mz-calendar
          .mz-calendar-header
            a( class="mz-calendar-prev-year" :title="translate('admin.calendar.prev_year')" @click="changeYear(-1,no)")
              | «
            a( class="mz-calendar-prev-month" :title="translate('admin.calendar.prev_month')" @click="changeMonth(-1,no)")
              | ‹
            a( class="mz-calendar-month-select" :title="translate('admin.calendar.select_month')" @click="showMonth(no)")
              | {{ translate('admin.calendar.month.' + months[get_now(no).getMonth()] ) }}
            a( class="mz-calendar-year-select" :title="translate('admin.calendar.select_year')" @click="showYear(no)")
              | {{ get_now(no).getFullYear() }}
            a( class="mz-calendar-next-month" :title="translate('admin.calendar.next_month')" @click="changeMonth(1,no)")
              | ›
            a( class="mz-calendar-next-year" :title="translate('admin.calendar.next_year')" @click="changeYear(1,no)")
              | »
          table( cellpadding="0" cellspacing="0" v-if="get_date(no)")
            tr
              th( v-for="day in days")
                | {{ translate('admin.calendar.days.' + day ) }}
            tr( v-for="i in 6")
              td( v-for="j in 7" v-text="get_date(no)[(i - 1) * 7 + j - 1].text" :title="get_date(no)[(i - 1) * 7 + j - 1].title" :class="get_date(no)[(i - 1) * 7 + j - 1].status" @click="select(get_date(no)[(i - 1) * 7 + j - 1], no)")
          .mz-calendar-year-panel( transition="mz-calendar-panel" v-if="that['showYear'+(no)]")
            a( class="mz-calendar-year-panel-prev" @click="changeYearRange(no,-1)")
            a( class="mz-calendar-year-panel-year" v-for="item in that['years'+(no)]" :class="item.status" @click="selectYear(item,no)")
              | {{ item.year }}
            a( class="mz-calendar-year-panel-next" @click="changeYearRange(no,1)")
          .mz-calendar-month-panel( transition="mz-calendar-panel" v-if="that['showMonth'+(no)]")
            a( v-for="item in this['months'+(no)]" class="mz-calendar-month-panel-month" :class="item.status" @click="selectMonth(item,no)")
              | {{ months[item.month-1].substr(0,3) }}
        .mz-calendar-separator( v-if="range && no===0")
          span
            | {{ translate('admin.calendar.to' ) }}
    .mz-calendar-bottom( v-if="range")
      a( class="mz-calendar-btn ok" @click="ok")
        | {{ translate('admin.calendar.ok' ) }}
</template>

<script>
import Root from '@/admin/mixins/root_computed.js'
export default{
  mixins: [Root],
  props: {
    modelValue: {
      type: [Date,String],
      required: false,
    },
    value: {
      type: [Date,String],
      required: false,
    },
    range: {
      type: Boolean,
      default: false
    },
    width: {
      default: 214
    },
    placeholder: {
      default: ''
    },
    startTime: {
      default() { return new Date(); }
    },
    endTime: {
    },
    maxRange: {
      coerce(val) {
        return + val;
      }
    },
    clearable: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: Boolean,
      default: false
    },
    onConfirm: Function
  },
  emits: ['update:modelValue', 'input'],

  data() {
    return {
      show: false,
      showYear1: false,
      showYear2: false,
      showMonth1: false,
      showMonth2: false,
      left: false,
      ranges: [],
      days: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'],
      months: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
      years1: [],
      years2: [],
      months1: [],
      months2: [],
      date1: null,
      date2: null,
      now_1: null,
      now_2: null,
      time_1: null,
      time_2: null,
      time: null
    };
  },

  created() {
    this.time = this.parse(this.modelValue || this.value);
  },

  computed: {
    that() { return this; },
    count() { return this.range ? 2 : 1 },

    localValue() {
      if (this.range) {
        if (this.startTime && this.endTime) {
          return this.stringify(this.parse(this.startTime, false)) + ' ~ ' + this.stringify(this.parse(this.endTime, false));
        } else {
          return '';
        }
      } else {
        return this.stringify(this.parse(this.time, false));
      }
    },

    time1: {
      get() {
        return this.time_1 || this.parse(this.time, false) || this.parse(this.startTime, false);
      },
      set(val) {
        return this.time_1 = val;
      }
    },

    time2: {
      get() {
        return this.time_2 || this.parse(this.endTime, true);
      },
      set(val) {
        return this.time_2 = val;
      }
    },

    now1: {
      get() {
        return this.now_1 || this.parse(new Date(), false);
      },
      set(val) {
        return this.now_1 = val;
      }
    },

    now2: {
      get() {
        return this.now_2 || this.parse(new Date(), true);
      },
      set(val) {
        return this.now_2 = val;
      }
    }
  },

  watch: {
    show(val) {
      this.hidePanel();
      if (val) { return this.$refs.popup.focus(); }
    },

    now_1(val) {
      return this.updateAll();
    },

    now_2(val) {
      return this.updateAll();
    },
    modelValue(val, oldVal){
      if (String(val) === String(oldVal)) return
      this.time = this.parse(val);
    },
    value(val, oldVal){
      if (String(val) === String(oldVal)) return
      this.time = this.parse(val);
    },

    time(val, oldVal) {
      if (String(val) === String(oldVal)) return
      this.$emit('update:modelValue', val)
      this.$emit('input', val)
    }
  },

  methods: {
    get_date(i) {
      return this['date' + i];
    },

    get_now(i) {
      return this['now' + i];
    },

    parse(time, isLast) {
      if (time) {
        const tmpTime = new Date(time);
        if (isLast === undefined) {
          return tmpTime;
        } else if (isLast) {
          return new Date(tmpTime.getFullYear(), tmpTime.getMonth(), tmpTime.getDate(), 23, 59, 59, 999);
        } else {
          return new Date(tmpTime.getFullYear(), tmpTime.getMonth(), tmpTime.getDate());
        }
      } else {
        return null;
      }
    },



    updateAll() {
      this.update(new Date(this.now1), 1);
      return this.range && this.update(new Date(this.now2), 2);
    },


    click() {
      this.updateAll();
      //@time1 = @parse(@startTime) || @parse(@time)
      //@now1 = @parse(@startTime) || new Date()
      if (this.range) {
        // @initRanges()
        this.time2 = this.parse(this.endTime);
        this.now2 = this.parse(this.endTime) || new Date();
      }

      const rect = this.$el.getBoundingClientRect();
      const right = document.documentElement.clientWidth - rect.left;
      if ((right < (this.range ? 441 : 214)) && (right < rect.left)) { this.left = true; } else { this.left = false; }
      return this.show = !this.show;
    },

    select(item, nom) {
      this.hidePanel();
      if (item.status.indexOf('mz-calendar-disabled') !== -1) {
        return;
      }

      this['now' + nom] = new Date(item.time);
      this['time' + nom] = new Date(item.time);

      if (!this.range) {
        this.time = this.getOutTime(item.time);
        return this.show = false;
      } else if (!this.confirm) {
        return this[nom === 1 ? 'startTime' : 'endTime'] = this.getOutTime(item.time);
      }
    },


    ok() {
      this.show = false;
      if (this.range && this.confirm) {
        this.startTime = this.getOutTime(this.time1);
        this.endTime = this.getOutTime(this.time2);
        return this.onConfirm && this.onConfirm(this.startTime, this.endTime);
      }
    },

    selectRange(item) {
      this.now1 = new Date(item.start);
      this.now2 = new Date(item.end);
      this.time1 = new Date(item.start);
      this.time2 = new Date(item.end);
      this.startTime = this.getOutTime(item.start);
      this.endTime = this.getOutTime(item.end);
      return this.hidePanel();
    },

    getOutTime(time) {
      const type = this.time ? typeof(this.time) : typeof(this.startTime);
      if (type === 'number') {
        return time.getTime();
      } else if (type === 'object') {
        return new Date(time);
      } else {
        return this.stringify(time);
      }
    },


    update(time, nom) {
      let i, tmpTime;
      let asc1, end;
      const arr = [];
      time.setDate(0);
      const curFirstDay = time.getDay();
      const lastDay = time.getDate();
      if (curFirstDay > 0) { // on April was offsetting
        let asc;
        for (i = curFirstDay, asc = curFirstDay <= 1; asc ? i <= 1 : i >= 1; asc ? i++ : i--) {
          const day = (lastDay - i) + 1;
          tmpTime = new Date(time.getFullYear(), time.getMonth(), day);
          tmpTime = this.parse(tmpTime, nom === 2);
          arr.push({
            status: this.getTimeStatus(tmpTime, nom) || 'mz-calendar-lastmonth',
            title: this.stringify(tmpTime),
            text: day,
            time: tmpTime
          });
        }
      }

      time.setMonth(time.getMonth() + 2, 0);
      const curDay = time.getDate();
      time.setDate(1);
      for (i = 1, end = curDay, asc1 = 1 <= end; asc1 ? i <= end : i >= end; asc1 ? i++ : i--) {
        tmpTime = new Date(time.getFullYear(), time.getMonth(), i);
        tmpTime = this.parse(tmpTime, nom === 2);
        arr.push({
          status: this.getTimeStatus(tmpTime, nom),
          title: this.stringify(tmpTime),
          text: i,
          time: tmpTime
        });
      }

      i = 0;
      while (arr.length < 42) {
        i++;
        tmpTime = new Date(time.getFullYear(), time.getMonth() + 1, i);
        tmpTime = this.parse(tmpTime, nom === 2);
        arr.push({
          status: this.getTimeStatus(tmpTime, nom) || 'mz-calendar-nextmonth',
          title: this.stringify(tmpTime),
          text: i,
          time: tmpTime
        });
      }
      return this['date' + nom] = arr;
    },

    getTimeStatus(time, nom, format) {
      let status = '';
      const curTime = new Date();
      const selTime = this['time' + nom];
      const tmpTimeVal = this.stringify(time, format || 'yyyy-MM-dd');
      const curTimeVal = this.stringify(curTime, format || 'yyyy-MM-dd');
      const selTimeVal = this.stringify(selTime, format || 'yyyy-MM-dd');
      if (tmpTimeVal === selTimeVal) {
        status = 'mz-calendar-selected';
      } else if (tmpTimeVal === curTimeVal) {
        status = 'mz-calendar-today';
      }

      if (this.time1 && this.time2 && (time >= this.time1) && (time <= this.time2)) {
        status += ' mz-calendar-inrange';
      }

      if ((nom === 1) && this.time2) {
        let minTime = new Date(this.time2);
        if (this.maxRange) {
          minTime.setDate(minTime.getDate() - this.maxRange);
          if (format === 'yyyy') {
            minTime = new Date(minTime.getFullYear(), 0, 1);
          }

          if (format === 'yyyy-MM') {
            minTime = new Date(minTime.getFullYear(), 0, 1);
          }

          if ((time < minTime) || (time > this.time2)) {
            status += ' mz-calendar-disabled';
          }

        } else if (time > this.time2) {
          status += ' mz-calendar-disabled';
        }

        if (time > this.time2) {
          status += ' mz-calendar-disabled';
        }
      }

      if ((nom === 2) && this.time1) {
        let maxTime = new Date(this.time1);
        if (this.maxRange) {
          maxTime.setDate(maxTime.getDate() + this.maxRange);
          if (format === 'yyyy') {
            maxTime = new Date(maxTime.getFullYear(), 11, 1);
          }

          if (format === 'yyyy-MM') {
            maxTime = new Date(maxTime.getFullYear(), maxTime.getMonth() + 1, 1);
          }

          if ((time > maxTime) || (time < this.time1)) {
            status += ' mz-calendar-disabled';
          }

        } else if (time < this.time1) {
          status += ' mz-calendar-disabled';
        }
      }

      return status;
    },

    stringify(time, format) {
      if (!time) { return ''; }

      format = format || this.format;
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const day = time.getDate();
      const hours24 = time.getHours();
      const hours = (hours24 % 12) === 0 ? 12 : hours24 % 12;
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      const milliseconds = time.getMilliseconds();
      const map = {
        yyyy: year,
        MM: ('0' + month).slice(-2),
        M: month,
        dd: ('0' + day).slice(-2),
        d: day,
        HH: ('0' + hours24).slice(-2),
        H: hours24,
        hh: ('0' + hours).slice(-2),
        h: hours,
        mm: ('0' + minutes).slice(-2),
        m: minutes,
        ss: ('0' + seconds).slice(-2),
        s: seconds,
        S: milliseconds
      };
      return format.replace(/y+|M+|d+|H+|h+|m+|s+|S+/g, str => map[str]);
    },

    showYear(nom) {
      const name = 'showYear' + nom;
      this.hidePanel(name);
      this[name] = !this[name];
      const time = new Date(this['now' + nom] || new Date());
      const num = Math.floor(time.getFullYear() % 10);
      const arr = [];
      time.setDate(1);
      time.setFullYear(time.getFullYear() - num);
      while (arr.length < 10) {
        (nom === 2) && time.setMonth(time.getMonth() + 1, 0);
        arr.push({
          year: time.getFullYear(),
          status: this.getTimeStatus(time, nom, 'yyyy'),
        });
        time.setDate(1);
        time.setFullYear(time.getFullYear() + 1);
      }

      return this['years' + nom] = arr;
    },

    showMonth(nom) {
      const name = 'showMonth' + nom;
      this.hidePanel(name);
      this[name] = !this[name];
      const time = new Date(this['now' + nom] || new Date());
      const arr = [];
      while (arr.length < 12) {
        time.setDate(1);
        time.setMonth(arr.length);
        (nom === 2) && time.setMonth(time.getMonth() + 1, 0);
        arr.push({
          month: arr.length + 1,
          status: this.getTimeStatus(time, false, 'yyyy-MM'),
        });
      }

      return this['months' + nom] = arr;
    },

    changeYearRange(nom, flag) {
      const arr = this['years' + nom];
      const time = new Date(this['time' + nom] || new Date());
      return (() => {
        const result = [];
        for (let i of arr) {
          const item = arr[i];
          const year = item.year + (10 * flag);
          time.setDate(1);
          time.setFullYear(year);
          (nom === 2) && time.setMonth(time.getMonth() + 1, 0);
          item.year = year;
          result.push(item.status = this.getTimeStatus(time, nom));
        }
        return result;
      })();
    },

    changeYear(flag, nom) {
      const now = this['now' + nom];
      now.setDate(1);
      now.setFullYear(now.getFullYear() + flag);
      (nom === 2) && now.setMonth(now.getMonth() + 1, 0);
      this['now' + nom] = new Date(now);
      return this.hidePanel();
    },

    changeMonth(flag, nom) {
      const now = this['now' + nom];
      now.setDate(1);
      now.setMonth(now.getMonth() + flag);
      (nom === 2) && now.setMonth(now.getMonth() + 1, 0);
      this['now' + nom] = new Date(now);
      return this.hidePanel();
    },

    selectYear(item, nom) {
      if (item.status.indexOf('mz-calendar-disabled') !== -1) {
        return;
      }

      const now = this['now' + nom];
      now.setFullYear(item.year);
      this['now' + nom] = new Date(now);
      return this.hidePanel();
    },

    selectMonth(item, nom) {
      if (item.status.indexOf('mz-calendar-disabled') !== -1) {
        return;
      }

      const now = this['now' + nom];
      now.setMonth(item.month - 1);
      this['now' + nom] = new Date(now);
      return this.hidePanel();
    },

    hidePanel(name) {
      return (() => {
        const result = [];
        for (let item of ['showYear1', 'showYear2', 'showMonth1', 'showMonth2']) {
          if (item !== name) { result.push(this[item] = false); } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    },

    clear() {
      this.time1 = (this.time2 = (this.startTime = (this.endTime = (this.time = null))));
      this.now1 = new Date();
      return this.now2 = new Date();
    }
  }
}
</script>

<style scoped>
  .mz-datepicker {
    vertical-align: middle;
    position: relative;
  }
  /*.mz-datepicker > input {
      color: #666;
      border: 1px solid #d9d9d9;
      height: 30px;
      box-sizing: border-box;
      outline: none;
      padding: 0 30px 0 7px;
      font-size: 13px;
      width: 100%;
      cursor: pointer;
  }*/
  .mz-datepicker > input:disabled {
    cursor: not-allowed;
    background-color: #F0F4FA;
  }
  .mz-datepicker > input:focus, .mz-datepicker > input.focus {
    border-color: #3bb4f2;
    -webkit-box-shadow: 0 0 5px rgba(59, 180, 242, .3);
    box-shadow: 0 0 5px rgba(59, 180, 242, .3);
  }
  .mz-datepicker > i {
    display: inline-block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    right: 0;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAABlBMVEUAAACZmZl+9SADAAAAAXRSTlMAQObYZgAAAB9JREFUCNdjEBSAov//GSYKMjQwAhlAEoTaHyIzIFIAFm0NVxDW3twAAAAASUVORK5CYII=') no-repeat 50% 50%;
  }
  .mz-datepicker > a {
    display: none;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    right: 0;
    cursor: pointer;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARBAMAAADJQ1rJAAAAMFBMVEUAAACZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmy/KNBAAAAD3RSTlMAeQfv0sgfSr2XgBXh4Em9LfYxAAAAdElEQVQI12NgYGAqXFgEpIAM4f//PwuBWFv/A8HXDUCh+P8gEMTAwPjf9v//P/YfGRiU/7v5A9FnBYb6/z9SvmT8/1/AIP//f9g0oFoBhvX////kBGpZgBBDqEPoBZpn8///X6B5CDsQ9iLcAmI+XPgISAEABvtVL6xNmm0AAAAASUVORK5CYII=') no-repeat 50% 50%;
  }
  .mz-datepicker > a:hover {
    opacity: 0.8;
  }
  .mz-datepicker:hover > a {
    display: block;
  }
  .mz-datepicker:hover > a + i {
    display: none;
  }
  .mz-datepicker-popup {
    font-size: 12px;
    position: absolute;
    top: 100%;
    margin-top: 1px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 1px 6px rgba(99, 99, 99, 0.2);
    padding: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #fff;
    outline: none;
    z-index: 2;
  }
  .mz-datepicker-popup-left{
    right: 0;
  }
  .mz-datepicker-popup-transition {
    transition: all 200ms ease;
    opacity: 1;
    transform: scaleY(1);
    transform-origin: center top;
  }
  .mz-datepicker-popup-enter, .mz-datepicker-popup-leave {
    opacity: 0;
    transform: scaleY(0)
  }
  .mz-calendar {
    width: 196px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .mz-calendar-header {
    text-align: center;
    color: #999;
    position: relative;
    line-height: 34px;
  }
  .mz-calendar-header a {
    cursor: pointer;
  }
  .mz-calendar-header a:hover {
    color: #1284e7;
  }
  .mz-calendar-prev-year, .mz-calendar-prev-month, .mz-calendar-next-month, .mz-calendar-next-year {
    position: absolute;
    font-size: 16px;
  }
  .mz-calendar-prev-year {
    left: 4px;
  }
  .mz-calendar-prev-month {
    left: 24px;
  }
  .mz-calendar-next-month {
    right: 24px;
  }
  .mz-calendar-next-year {
    right: 4px;
  }
  .mz-calendar-year-select, .mz-calendar-month-select {
    font-weight: 700;
    color: #666;
    padding: 0 2px;
  }
  .mz-calendar-footer {}
  .mz-calendar th, .mz-calendar td {
    width: 28px;
    height: 28px;
    text-align: center;
    box-sizing: border-box;
  }
  .mz-calendar th {
    font-weight: normal;
  }
  .mz-calendar td {
    cursor: pointer;
  }
  .mz-calendar td:hover, .mz-calendar-inrange {
    background: #eaf8fe;
  }
  .mz-calendar-lastmonth, .mz-calendar-nextmonth {
    color: #ccc;
  }
  .mz-calendar-today {
    border: solid 1px #1284e7;
    font-weight: bold;
    color: #1284e7;
  }
  .mz-calendar-selected {
    color: #fff;
    font-weight: bold;
    background: #1284e7 !important;
  }
  .mz-calendar-disabled {
    cursor: not-allowed !important;
    color: #bcbcbc !important;
    background: #f3f3f3 !important;
  }
  .mz-calendar-top {
    color: #616161;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #f3f3f3;
  }
  .mz-calendar-top a {
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    cursor: pointer;
  }
  .mz-calendar-top a:hover {
    color: #77BDFB;
  }
  .mz-calendar-top a.on {
    font-weight: bold;
    color: #1284e7;
  }
  .mz-calendar-top i {
    content: '|';
    display: inline-block;
    width: 1px;
    margin: 0 10px;
    height: 16px;
    background: #616161;
    vertical-align: middle;
  }
  .mz-calendar-bottom {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid #f3f3f3;
    text-align: right;
  }
  .mz-calendar-btn {
    display: inline-block;
    height: 22px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 2px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    padding: 0 10px;
    line-height: 22px;
    color: #1284e7;
    margin-left: 5px;
  }
  .mz-calendar-btn:hover {
    color: #60B3FB;
  }
  .mz-calendar-btn:active {
    color: #006CCA;
  }
  .mz-calendar-btn.ok {
    background: #1284e7;
    color: #fff;
  }
  .mz-calendar-btn.ok:hover {
    background: #60B3FB;
  }
  .mz-calendar-btn.ok:active {
    background: #006CCA;
  }
  .mz-calendar-range {
    width: 423px;
  }
  .mz-calendar-separator {
    display: inline-block;
    vertical-align: middle;
    width: 31px;
    position: relative;
  }
  .mz-calendar-separator::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 180px;
    background: #e0e0e0;
    left: 50%;
    top: 50%;
    margin-top: -90px;
  }
  .mz-calendar-separator span {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background: #fff;
    top: 50%;
    margin-top: -15px;
  }
  .mz-calendar-year-panel {
    position: absolute;
    left: 0;
    top: 34px;
    width: 100%;
    height: 196px;
    background: #fff;
  }
  .mz-calendar-year-panel-year, .mz-calendar-year-panel-prev,.mz-calendar-year-panel-next {
    display: inline-block;
    text-align: center;
    width: 50%;
    height: 32px;
    line-height: 32px;
    vertical-align: middle;
    box-sizing: border-box;
  }
  .mz-calendar-year-panel-prev, .mz-calendar-year-panel-next {
    width: 100%;
    height: 18px;
    position: relative;
    display: block;
  }
  .mz-calendar-year-panel-prev::before,.mz-calendar-year-panel-next::before{
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -3px;
    margin-top: -3px;
  }
  .mz-calendar-year-panel-prev::before{
    border-bottom: 5px solid #999;
  }
  .mz-calendar-year-panel-next::before{
    border-top: 5px solid #999;
  }
  .mz-calendar-year-panel-year:hover,.mz-calendar-month-panel-month:hover, .mz-calendar-year-panel-prev:hover,.mz-calendar-year-panel-next:hover {
    background: #eaf8fe;
    cursor: pointer;
  }
  .mz-calendar-month-panel {
    position: absolute;
    left: 0;
    top: 34px;
    width: 100%;
    height: 196px;
    background: #fff;
  }
  .mz-calendar-month-panel-month {
    display: inline-block;
    text-align: center;
    width: 33.33%;
    height: 25%;
    line-height: 49px;
    vertical-align: middle;
    box-sizing: border-box;
  }
  .mz-calendar-panel-transition {
    transition: all 200ms ease;
    opacity: 1;
    transform: scale(1);
    transform-origin: center center;
  }
  .mz-calendar-panel-enter, .mz-calendar-panel-leave {
    opacity: 0;
    transform: scale(0)
  }
</style>
