<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .modal-header
      h2( v-if="menu.handle")
        | {{translate('admin.menus.new.edit')}}
      h2( v-if="!menu.handle")
        | {{translate('admin.menus.new.title')}}
      .icon-delete( @click="closeModal")

    .modal-content
      .field
        helpers-label.label(field="menu.name")
        input( name="menu.name" v-model="menu.name" :class="{invalid: isInvalid('menu.name')}")
        helpers-validation-message.error(:errors="validation" field="menu.name")

      .field
        helpers-label.label(field="menu.handle")
        input( name="menu.handle" v-model="menu.handle" :class="{invalid: isInvalid('menu.handle')}" @keyup="stopGenHandle")
        helpers-validation-message.error(:errors="validation" field="menu.handle")

    .modal-footer( v-if="menu.handle")
      .left
        .button.delete.inverted.short( @click="deleteConfirm")
          | {{translate('admin.buttons.delete')}}
      .right
        button.button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.buttons.save')}}
    .modal-footer( v-if="!menu.handle")
      .left
        .button.secondary.inverted.short( @click="closeModal")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.menus.new.add_menu')}}
</template>
<script>
import base from '../../mixins/base.js'
import gen_handle from '../../mixins/gen_handle.js';
export default{
  mixins: [base, gen_handle],
  model_klass: "menu", // for handler

  data() {
    return {
      menu: {},
      onUpdate: null
    };
  },


  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (!this.$validate()) { return; }
      if (this.menu.id) {
        this.onUpdate(this.menu);
      } else {
        this.onCreate(e);
      }
      Cache.deleteBy('menus');
    },

    onCreate(e) {
      return this.axios.post("menus", {menu: this.menu || {}}).then(response => {
        this.menu = response;
        this.closeModal();
        this.navigate(`/menus/${this.menu.id}/edit`);
      }).catch(resp => this.set_validation2(this, 'menu', resp));
    },


    deleteConfirm(e){
      e.preventDefault();
      this.modal = null;
      this.modal_delete = { type: "menu", yes_function: this.onDestroy };
    },

    onDestroy(e) {
      e.preventDefault();
      this.axios.delete(`menus/${this.menu.id}`).then(response => {
        this.closeModal();
        this.navigate("/menus");
      });
    }
  }
}
</script>