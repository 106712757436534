<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .modal-header
      h2
        | {{ translate('admin.settings.edit.edit_store_information' ) }}
      .icon-delete( @click="onCancel")

    .modal-content
      .field
        helpers-label(field="settings.name")
        input(name="settings.name" v-model="settings.name" :class="{invalid: isInvalid('settings.name')}")
        helpers-validation-message.error(:errors="validation" field="settings.name")

      .field
        helpers-label.label(field="settings.email")
        input( name="settings.email" v-model="settings.email" :class="{invalid: isInvalid('settings.email')}")
        helpers-validation-message.error(:errors="validation" field="settings.email")

    .modal-footer
      .left
        button.v3-button.secondary.inverted.short( @click="onCancel")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.v3-button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.buttons.save')}}
</template>

<script>
import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  data() {
    return {settings: {}};
  },

  validations: {
    "settings.name":               "required, maxLength: 255",
    "settings.email":              "required, email, maxLength: 255"
  }
}
</script>
