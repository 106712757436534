<template lang="pug">
form( @submit.prevent="onSetPrimary" @keydown.esc="onCancel")
    .modal-header
      h2
        | {{ translate('admin.domains.buy.title' ) }}
      .icon-delete( @click="closeModal")

    .modal-content
      .field.special-field.http
        helpers-label(field="domain.name")
        .field-container
          .field-helper http://
          input( name="domain.name" v-model="domain.name" :class="{invalid: isInvalid('domain.name')}")
        helpers-validation-message.error(:errors="validation" field="domain.name")
        .success( v-if="success")
          .icon-check
          | {{ translate('admin.domains.buy.success' ) }}

      .field
        helpers-label.label(field="domain.top-level")
        .select.fancy-select.domains( @click="openDropDownMenu")
          .fake-input( data="fake-name" v-text="current_domain")
          ul.custom-dropdown
            //li  :class="{active: primary_domain_id == option.value}" @click=="primary_domain_id=option.value; current_domain=option.text"
            li
              | .com (14.85 EUR/year)
            li
              | .ee (29.85 EUR/year)
            li
              | .eu (14.85 EUR/year)
        helpers-validation-message.error(:errors="validation" field="domain.id")

      .buttons
        button.button.primary.shorter
          | {{translate('admin.domains.buy.check_availability' )}}

      .contact
        .contact-address.clearfix
          h3
            | {{translate('admin.domains.buy.contact-information' )}}
          .show( v-if="!edit_contact")
            .email
              | adam.massey@gmail.com
            .phone
              | +372 555 37 846
            .fake-link( @click="edit_contact=!edit_contact")
              | {{translate('admin.buttons.edit')}}

          .edit( v-if="edit_contact")
            .field
              helpers-label(field="domain.email")
              .field-container
                input( name="domain.email" v-model="domain.email" :class="{invalid: isInvalid('domain.email')}")
              helpers-validation-message.error(:errors="validation" field="domain.email")

            .field
              helpers-label(field="domain.phone")
              .field-container
                input( name="domain.phone" v-model="domain.phone" :class="{invalid: isInvalid('domain.phone')}")
              helpers-validation-message.error(:errors="validation" field="domain.phone")

            .buttons
              button.button.primary.shorter
                | {{translate('admin.buttons.save_changes')}}

        .billing-address.clearfix
          h3
            | {{translate('admin.domains.buy.billing-address' )}}
          .show( v-if="!edit_billing")
            .address
              | Juhkentalli 52-6
            .zip-city
              | 13056, Tallinn,
            .county-country
              | Harju, Estonia
            .fake-link( @click="edit_billing=!edit_billing")
              | {{translate('admin.buttons.edit')}}

          .edit( v-if="edit_billing")
            .field
              helpers-label.label(field="domain.address1")
              input( name="domain.address1" v-model="domain.address1" :class="{invalid: isInvalid('domain.address1')}")
              helpers-validation-message.error(:errors="validation" field="domain.address1")
              .additional-address( @click="additional_address=!additional_address" v-if="!additional_address")
                | {{ translate('admin.shared.add_address_line' ) }}

            .field( v-if="additional_address")
              helpers-label.label(field="domain.address2")
              input( name="domain.address2" v-model="domain.address2" :class="{invalid: isInvalid('domain.address2')}")
              helpers-validation-message.error(:errors="validation" field="domain.address2")

            .field
              helpers-label.label(field="domain.city")
              input( name="domain.city" v-model="domain.city" :class="{invalid: isInvalid('domain.city')}")
              helpers-validation-message.error(:errors="validation" field="domain.city")

            .field
              helpers-label.label(field="domain.zip")
              input( name="domain.city" v-model="domain.zip" :class="{invalid: isInvalid('domain.zip')}")
              helpers-validation-message.error(:errors="validation" field="domain.zip")

            .field( v-if="countries.length")
              helpers-label.label(field="domain.country_id")
              .select.fancy-select( @click="openDropDownMenu")
                .fake-input( data="fake-name")
                  | {{attr_by_key(countries.findById(domain.country_id), 'name')}}
                ul.custom-dropdown
                  li( v-for="country in country_list()" v-text="country.text" :class="{active: domain.country_id==country.value}" @click="domain.country_id=country.value")
              helpers-validation-message.error(:errors="validation" field="domain.country_id")

            .field( v-if="states.length")
              helpers-label.label(field="domain.state_id")
              .select.fancy-select( @click="openDropDownMenu")
                .fake-input( data="fake-name")
                  | {{attr_by_key(states.findById(domain.state_id), 'name')}}
                ul.custom-dropdown
                  li( v-for="state in state_list()" v-text="state.text" :class="{active: domain.state_id==state.value}" @click="domain.state_id=state.value")
              helpers-validation-message.error(:errors="validation" field="domain.state_id")

            .buttons
              button.button.primary.shorter
                | {{translate('admin.buttons.save_changes')}}

    .modal-footer
      .left
        .button.secondary.inverted.short( @click="closeModal")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.button.primary.short( type="submit")
          | {{translate('admin.domains.buy.buy' )}}
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
export default{
  mixins: [Root],
  data() {
    return {
      domain: {},
      edit_contact: false,
      edit_billing: false
    };
  },

  methods: {
    success() {
      // if name exists and domain name is valid
      return true;
    }
  }
}
</script>
