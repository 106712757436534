<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .modal-header
      h2
        | {{ translate('admin.settings.edit.edit_standards_and_formats' ) }}
      .icon-delete( @click="onCancel")

    .modal-content
      .field.clearfix
        helpers-label.label(field="settings.time_zone")
        select.fancy-select-2( v-model="settings.time_zone")
          option( v-for="option in time_zone_options" v-text="option.text" :value="option.value")
        helpers-validation-message.error(:errors="validation" field="settings.time_zone")

      .field.clearfix
        helpers-label.label(field="settings.currency")
        select.fancy-select-2( v-model="settings.currency_id")
          option( v-for="currency in currencies" v-text="currencyVisual(currency)" :value="currency.id")
        helpers-validation-message.error(:errors="validation" field="settings.currency_id")

      .field.clearfix
        helpers-label.label(field="settings.unit_system")
        select.fancy-select-2( v-model="settings.unit_system")
          option( v-for="option in unit_system_options" v-text="option.text" :value="option.value")
        helpers-validation-message.error(:errors="validation" field="settings.unit_system")

    .modal-footer
      .left
        button.v3-button.secondary.inverted.short( @click="onCancel")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.v3-button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.buttons.save')}}
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  data() {
    return {
      unit_systems: [],
      time_zones: [],
      settings: {}
    };
  },

  validations: {
    "settings.time_zone":   "required",
    "settings.unit_system": "required",
    "settings.currency_id": "required"
  },

  computed: {
    time_zone_options() {
      return (this.time_zones || []).map(item => ({
        text: item.name,
        value: item.value
      }));
    },

    unit_system_options() {
      return (this.unit_systems || []).map(item => ({
        text: item.name,
        value: item.value
      }));
    }
  },
  created() {
    this.fetch_cached_collection('time_zones')
    this.fetch_cached_collection('unit_systems')
  },

  methods: {
    currencyVisual(currency) {
      let {
        name
      } = currency;
      if (currency.symbol) { name += ', ' + currency.symbol; }
      return name;
    }
  }
}
</script>
