<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .modal-header
      h2
        | {{ translate('admin.settings.edit.edit_storefront_access' ) }}
      .icon-delete( @click="onCancel")

    .modal-content
      .field
        helpers-label.label(field="settings.private_shop_message")
        textarea( name="settings.private_shop_message" v-model="settings.private_shop_message" :class="{invalid: isInvalid('settings.private_shop_message')}")
        helpers-validation-message.error(:errors="validation" field="settings.private_shop_message")

    .modal-footer
      .left
        .button.secondary.inverted.short( @click="onCancel")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.buttons.save')}}
</template>

<script>
import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  data() {
    return {settings: {}};
  },

  validations: {
    "settings.private_shop_message": "required, maxLength: 2000"
  }
}
</script>
