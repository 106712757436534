<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .modal-header
      h2
        | Generate Codes
      .icon-delete( @click="onCancel")

    .modal-content
      .field.special-field.date
        helpers-label.label(field="generate.code_length")
        .field-container
          input(:placeholder="'how big is your code'"  v-model.number="generate.code_length")
          dimension_unit(:variant="generate" name="code_length" :options="[16,12,8]")
        helpers-validation-message.error(:errors="validation" field="generate.code_length")

      .field
        helpers-label.label(field="generate.expiration_value")
        input( name="generate.expiration_value" v-model.number="generate.expiration_value" :class="{invalid: isInvalid('generate.expiration_value')}")
        helpers-validation-message.error(:errors="validation" field="generate.expiration_value")

      .field.special-field.date
        // | (Expires in)
        helpers-label.label(field="generate.expiration_entity")
        .field-container
          input( :placeholder="'days'" v-model="generate.expiration_entity")
          dimension_unit(:variant="generate" name="expiration_entity" :options="['days','months','years']")
        helpers-validation-message.error(:errors="validation" field="generate.expiration_entity")

      .field
        helpers-label.label(field="generate.locale" :onclick="openDropDownMenu")
        .select.fancy-select.with-input( @click="openDropDownMenu")
          .fake-input( data="fake-name" @click="openDropDownMenu")
            | {{ translate('admin.languages.' + generate.locale ) }}
          .custom-dropdown
            ul
              li( v-for="lang in all_possible_languages" @click="generate.locale=locale")
                | {{ translate('admin.languages.' + lang ) }}

      .field.special-field.money
        helpers-label.label(field="generate.amount")
        .field-container
          input( v-model.number="generate.amount" placeholder="0.00" :class="{invalid: isInvalid('generate.amount')}" @input="setPosition")
          .field-helper( v-text="currency(current_shop).code")
        helpers-validation-message.error(:errors="validation" field="generate.amount")

      .field
        helpers-label.label(field="generate.qty")
        input( name="generate.qty" v-model.number="generate.qty" :class="{invalid: isInvalid('generate.qty')}")
        helpers-validation-message.error(:errors="validation" field="generate.qty")

    .modal-footer
      .left
        .button.secondary.inverted.short( @click="closeModal")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.button.primary.short( type="submit" @click="onSubmit")
          | Generate
</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
import dimension_unit from '../variants/attribute/dimension-unit.vue';
export default{
  mixins: [Root],
  components:{dimension_unit},
  data() {
    return {generate: {}};
  },

  validations: {
    "generate.code_length":       "required",
    "generate.expiration_value":  "required, numeric",
    "generate.expiration_entity": "required",
    "generate.amount":            "required, numeric",
    "generate.qty":               "required, numeric"
  },

  methods: {
    onSubmit(e){
      e.preventDefault();
      if (!this.$validate()) { return; }

      const request = this.inJson(this.api_request("POST", "/gift_cards/generate", {cards: this.generate}));

      request.catch(response => {
        this.set_validation(this,"generate", response);
        return Cache.deleteBy('gift_card');
      });
      return request.then(obj => {
        Cache.deleteBy('gift_card');
        this.closeModal();
        return this.navigate("/gift-cards");
      });
    },

    onCancel(e){
      e.preventDefault();
      return this.closeModal();
    },

    setPosition(e) {
      const values = [e.target.selectionStart, e.target.selectionEnd];
      return this.$nextTick(() => {
        return e.target.setSelectionRange(...values);
      });
    }
  }
}
</script>
