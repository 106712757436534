<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .modal-header
      h2
        | {{ translate('admin.settings.edit.edit_store_information' ) }}
      .icon-delete( @click="onCancel")

    .modal-content
      .field
        helpers-label.label(field="settings.address.first_name")
        input( name="settings.address.first_name" v-model="settings.address.first_name" :class="{invalid: isInvalid('settings.address.first_name')}")
        helpers-validation-message.error(:errors="validation" field="settings.address.first_name")

      .field
        helpers-label.label(field="settings.address.last_name")
        input( name="settings.address.last_name" v-model="settings.address.last_name" :class="{invalid: isInvalid('settings.address.last_name')}")
        helpers-validation-message.error(:errors="validation" field="settings.address.last_name")

    .modal-footer
      .left
        button.v3-button.secondary.inverted.short( @click="onCancel")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.v3-button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.buttons.save')}}
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  data() {
    return {
      settings: {
        address: {}
      }
    };
  },

  validations: {
    "settings.address.first_name":       "required, maxLength: 255",
    "settings.address.last_name":       "required, maxLength: 255"
  }
}
</script>
