<template lang="pug">
form
  .modal-header
    h2 Creating packages
    .icon-delete( @click="closeModal")
  .modal-content(v-if="done.length < orders.length")
    .field
      | Loading {{done.length}} of {{orders.length}} ....
  .modal-content(v-else)
    .field(v-if="Object.keys(errors).length>0")
      p Errors:
      ul
        li(v-for="(err,num) of errors")
          | \#{{num}} - {{err}}

    .field(v-if="!loading && parcels.length>0")
      a(:href="`/api/v1/orders/shipping_parcels/mass_shipping_labels?where=id:${this.parcels.join('|')}&current_shop=${current_shop.domain}`") Labels

  .modal-footer
    .left
      .button.secondary.inverted.short( @click="closeModal")
        | {{translate('admin.buttons.cancel')}}
</template>
<script>
import Root from '@/admin/mixins/root_computed.js'
export default {
  mixins: [Root],
  data(){
    return {
      loading: true,
      done: [],
      sectors: [],
      orders: [],
      errors: {},
      parcels: []
    }
  },
  async mounted() {
    await this.fetch_cached_collection("sectors")
    let reqs = []
    for (const o of this.orders) reqs.push(this.prepareShipping(o))
    Promise.all(reqs).then(_=>this.loading=false)
  },
  methods: {
    // id is an order_id
    async prepareShipping(order){
      if (!order.shipping_method_id){
        this.errors[order.number] = "no shipping method"
        return this.done.push(order.id)
      }
      if (!order.shipping_method_id){
        this.errors[order.number] = "no shipping method"
        return this.done.push(order.id)
      }
      if (!this.sectors[0]){
        this.errors[order.number] = "no warehouse/sector set"
        return this.done.push(order.id)
      }
      if (order.calc_state === "canceled"){
        this.errors[order.number] = "canceled"
        return this.done.push(order.id)
      }

      const s_meth  = (await this.axios.get(`/order_shipping_methods/${order.shipping_method_id}`)).data
      const items   = await this.fetch_cached_collection_all(`order_items`, {where: `order_id:${order.id}`})

      let params = {
        notify: false,
        order_id: order.id,
        stock_warehouse_id: this.sectors[0].warehouse_id,
        shipping_items_attributes: [],
        parcel_shipping_method_attributes: s_meth
      }
      for (const item of items){
        if (item.amount_not_shipped === 0) continue;
        params.shipping_items_attributes.push({
          order_item_id: item.id,
          amount: item.amount_not_shipped,
          stock_sector_id: this.sectors[0].id
        })
      }
      console.log("params", params)

      if (params.shipping_items_attributes.length > 0){
        const new_parcel = (await this.axios.post(`orders/${order.id}/shipping_parcels`,
          {shipping_parcel: params}
        )).data
        console.log("created new parcel", new_parcel)
      }

      const parcels = await this.fetch_collection(`/orders/${order.id}/shipping_parcels`)
      for (const parcel of parcels){
        console.log("parcel", parcel)
        console.log("parcel_shipping_method", parcel.parcel_shipping_method.provider)
        await this.registerParcel(order, parcel)
      }
    },
    async registerParcel(order, parcel){
      try{
        const resp = (await this.axios.get(`/orders/${order.id}/shipping_parcels/${parcel.id}/register_parcel`)).data
        // this.parcels[parcel.parcel_shipping_method.provider] || this.$set(this.parcels, parcel.parcel_shipping_method.provider,[])
        this.parcels.push(parcel.id)
        this.done.push(order.id)
        console.log("parcel registered", resp)
      }catch(e){
        console.error(window.e=e)
        if (e.errors)
          this.errors[order.number] = e.errors.messages.base[0]
        else if (Object.keys(e.errors.messages).length > 0)
          this.errors[order.number] = JSON.stringify(e.errors.messages)
        else
          this.errors[order.number] = "shipping provider is not suitable"
        this.done.push(order.id)
      }
    },
  }
}
</script>
