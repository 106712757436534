<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .modal-header
      h2
        | SEO
      .icon-delete( @click="onCancel")

    .modal-content
      .field
        label.label( for="settings_meta_title")
          | {{ translate('activerecord.attributes.product.meta_title') }}
        input( id="settings_meta_title" name="settings.meta_title" v-model="settings.meta_title" :class="{invalid: isInvalid('settings.meta_title')}")

      .field
        label.label( for="settings_meta_keywords")
          | {{ translate('activerecord.attributes.product.meta_keywords') }}
        input( id="settings_meta_keywords" name="settings.meta_keywords" v-model="settings.meta_keywords" :class="{invalid: isInvalid('settings.meta_keywords')}")

      .field
        label.label( for="settings_meta_description")
          | {{ translate('activerecord.attributes.product.meta_description') }}
        textarea( id="settings_meta_description" name="settings.meta_description" v-model="settings.meta_description" :class="{invalid: isInvalid('settings.meta_description')}")

    .modal-footer
      .left
        button.v3-button.secondary.inverted.short( @click="onCancel")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.v3-button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.buttons.save')}}
</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  data() {
    return {
      settings: {
        meta_title: "",
        meta_keywords: "",
        meta_description: ""
      }
    };
  }
}
</script>
