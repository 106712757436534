<template lang="pug">
form( @keydown.esc="onCancel")
    .modal-header
      h2
        slot( name="header")
      .icon-delete( @click="closeModal")

    .modal-content
      slot( name="field")

      .field.clearfix
        .left
          | {{translate('admin.translations.edit.publish')}}
        .right
          .toggle-container
            toggle-v2(field="published_toggle", :object="that")

      //.field.clearfix v-if=="published_toggle == 'active'"
        .left
          | {{translate('admin.translations.edit.set_as_default')}}
        .right
          .toggle-container
            admin-toggle-v2 :field=="'primary_toggle'"

    .modal-footer
      .left
        slot( name="left")
      .right
        slot( name="right")
</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
const to_toggle_map= {
 "true": "active",
 "false": "hidden"
};
const from_toggle_map= {
    "active": true,
    "hidden": false
};
export default{
  mixins: [Root],
  data() {
    return {
      published_toggle: false,
      primary_toggle:   false
    };
  },
  mounted() {
      this.published_toggle= to_toggle_map[this.$parent.published.toString()];
      this.primary_toggle= to_toggle_map[this.$parent.primary.toString()];
    },

  watch: {
    published_toggle(value) { this.$set(this.$parent, "published", from_toggle_map[value]); },
    primary_toggle(value) { this.$set(this.$parent, "primary", from_toggle_map[value]); }
  },

  computed:{
    that() { return this; }
  },
  methods: {
    onCancel() { return this.closeModal(); }
  }
}
</script>
