<template>
  <form @keydown.esc="onCancel" @submit="onSubmit">
    <div class="modal-header">
      <h2 v-if="subscription.id">{{translate('admin.customer_subscriptions.edit.edit')}}</h2>
      <h2 v-if="!subscription.id">{{translate('admin.customer_subscriptions.edit.new')}}</h2>
      <div @click="onCancel" class="icon-delete"></div>
    </div>
    <div class="modal-content">

      {{subscription}}
      <div class="field">
        <helpers-label class="label" field="subscription.plan"></helpers-label>
        <select-autocomplete v-model="subscription.plan" :collection="plans" />
        <helpers-validation-message :errors="validation" class="error" field="subscription.plan_id"></helpers-validation-message>
      </div>

      <!--  not best one one as we may have a lot of customers and we will. So, should we use pure search? -->
      <div class="field">
        <helpers-label class="label" field="subscription.customer"></helpers-label>
        <select-autocomplete v-model="subscription.customer" :collection="customers" />
        <helpers-validation-message :errors="validation" class="error" field="subscription.customer_id"></helpers-validation-message>
      </div>

      <div class="field">
        <helpers-label class="label" field="subscription.starts_at"></helpers-label>
        <datepicker v-model="subscription.starts_at" format="dd/MM/yyyy" placeholder="--/--/----" />
        <helpers-validation-message :errors="validation" class="error" field="subscription.starts_at"></helpers-validation-message>
      </div>




    </div>
    <div class="modal-footer" v-if="subscription.id">
      <div @click="deleteConfirm" class="v3-button delete inverted short">{{translate('admin.buttons.delete')}}</div>
      <button @click="onSubmit" class="v3-button primary short" type="submit">{{translate('admin.buttons.save')}}</button>
    </div>
    <div class="modal-footer" v-if="!subscription.id">
      <div @click="closeModal" class="v3-button secondary inverted">{{translate('admin.buttons.cancel')}}</div>
      <button @click="onSubmit" class="v3-button primary" type="submit">{{'adminv-3.subscriptions.edit.add_subscription' }}</button>
    </div>
  </form>
</template>
<style lang="scss">
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
</style>
<script>
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import Root from '@/admin/mixins/root_computed.js';
import datepicker from '@/admin/components/helpers/datepicker.vue'
export default{
  components: {datepicker},
  mixins: [Root],
  data() {
    return {
      subscription: {},
      plans: [],
      customers: []
    };
  },

  validations: {
    "subscription.plan_id":        "required",
    "subscription.customer_id":    "required",
    "subscription.starts_at":      "required"
  },

  watch: {
    "subscription.plan"(obj, old){
      return this.$set(this.subscription, "plan_id", obj?.id);
    },
    "subscription.customer"(obj, old){
      return this.$set(this.subscription, "customer_id", obj?.id);
    }
  },
  mounted() {
    this.$set(this.subscription, "plan", this.subscription.cached_plan);
    this.$set(this.subscription, "customer", this.customers.findById(this.subscription.customer_id));
  },

  created() {
    this.fetch_cached_collection_all("customer_subscription_plans").then(col=> { return this.plans = col; });

    // not best one one as we may have a lot of customers and we will :)
    this.fetch_cached_collection_all("customers", {fields: ['id', 'name', 'first_name', 'last_name']}).then(col=> {
      this.customers = [];
      for (let c of col) {
        c.name = `${c.first_name} ${c.last_name}`;
        this.customers.push(c);
      }
    });
  },
  methods: {
    onSubmit(e){
      let request;
      e.preventDefault();
      if (!this.$validate()) { return; }

      if (this.subscription.id) {
        request = this.inJson(this.api_request("PATCH", `/customer_subscriptions/${this.subscription.id}`, {customer_subscription: this.subscription}));
      } else {
        request = this.inJson(this.api_request("POST", "/customer_subscriptions", {customer_subscription: this.subscription}));
      }

      request.catch(response => {
        this.set_validation(this,"subscription", response);
        Cache.deleteBy('customer_subscription');
      });
      return request.then(obj => {
        Cache.deleteBy('customer_subscription');
        this.closeModal();
        // redirect to new object
        if (!this.subscription.id) { this.navigate("/customer-subscriptions"); }
      });
    },


    onCancel(e){
      e.preventDefault();
      this.closeModal();
    },


    deleteConfirm(e){
      e.preventDefault();
      this.modal_delete = { type: "subscription", yes_function: this.onDestroy };
    },

    onDestroy(e) {
      e.preventDefault();
      this.api_request("DELETE", `customer_subscriptions/${this.subscription.id}`).then(response => {
        Cache.deleteBy('customer_subscription');
        this.closeModal();
        this.navigate('/customer-subscriptions');
      });
    }
  }
}
</script>
