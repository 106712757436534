<template lang="pug">
.delete-modal-container
    div.delete-modal-bg( @click="closeModal")
    div.delete-modal( ref="delete_modal")
      h2
        | {{translate('admin.modal_delete.'+type+'.title')}}
      .description( v-html="text||translate('admin.modal_delete.'+type+'.description')")

      .modal-footer.clearfix
        .left
          .button.secondary.inverted.small.short( @click="closeModal")
            | {{translate('admin.buttons.cancel')}}
        .right
          button.button.delete.inverted.small.short( type="submit" @click="onSubmit")
            | {{translate('admin.buttons.yes_delete' )}}

</template>
<script>
import Root from '@/admin/mixins/root_computed.js'
export default{
  mixins: [Root],
  props: {
    type: {default: ""},
    yes_function: {type: Function},
    text: {}
  },

  methods: {
    closeModal(e){
      e.preventDefault();
      this.modal_delete = null;
    },
    onSubmit(e){
      e.preventDefault();
      this.yes_function(e);
      this.closeModal(e);
    }
  }
}
</script>
