<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    template( v-if="ready")
      .modal-header
        h2
          | {{translate('admin.product.edit.delete' )}}
        .icon-delete( @click="closeModal")

      .modal-footer
        .left.buttons
          .button.secondary.inverted.short(@click="closeModal")
            | {{translate('admin.buttons.cancel')}}
        .right
          button.button.primary.short( type="submit" @click="onSubmit")
            | {{translate('admin.buttons.delete')}}
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '../../../mixins/root_computed.js';
export default{
  mixins: [Root],
  data() {
    return {
      onDone: null,
      ready: false
    };
  },

  methods: {
    data_synced() { return this.ready = true; },

    onSubmit(e) {
      e?.preventDefault();
      return this.onDone?.(() => this.closeModal());
    }
  }
}
</script>