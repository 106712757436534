<template lang="pug">
form(@submit="onSubmit" @keydown.esc="onCancel")
    .header.clearfix
      h2
        .title
          | {{ translate('admin.themes.delete.message' ) }}
    .footer.clearfix
      .buttons
        .right
          a.empty-button.close-reveal-modal( href="" @click="onCancel")
            | {{ translate('admin.buttons.cancel') }}
          button.button.primary.submit( type="submit" @click="onSubmit" :disabled="inProgress" :class="{disabled: inProgress}")
            | {{ translate('admin.buttons.delete') }}
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '../../../mixins/root_computed.js';
export default{
  mixins: [Root],
  data() {
    return {
      onConfirm: null,
      inProgress: false
    };
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.inProgress = true;
      return this.onConfirm?.( () => {
        this.inProgress = false;
        return this.closeModal();
      });
    },

    onCancel(e) {
      e.preventDefault();
      return this.closeModal();
    }
  }
}
</script>