<template lang="pug">
form( @keydown.esc="onCancel")
    .header.clearfix
      h2
        .title
          | {{ translate('admin.domains.custom.title' ) }}

    .content.clearfix( v-html="translate('admin.domains.custom.description', {domain: domain.hostname})")

    .footer.clearfix
      .buttons
        .button.secondary( @click="closeModal")
          | {{ translate('admin.buttons.close' ) }}
</template>
<script>
  export default{
    data() {
      return {domain: {}};
    }
  }
</script>