<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .modal-header
      h2
        | {{ translate('admin.settings.edit.edit_order_id_format' ) }}
      .icon-delete( @click="onCancel")

    .modal-content
      .field
        helpers-label.label(field="settings.sequence_format")
        input( name="settings.sequence_format" v-model="settings.sequence_format" :class="{invalid: isInvalid('settings.sequence_format')}")
        helpers-validation-message.error(:errors="validation" field="settings.sequence_format")

    .modal-footer
      .left
        button.v3-button.secondary.inverted.short( @click="onCancel")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.v3-button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.buttons.save')}}

</template>
<script>
  import Root from '@/admin/mixins/root_computed.js';
  import settings from './settings.js';
  export default{
    mixins: [Root,settings],

    data() {
      return {settings: {}};
    }
  }
</script>
