/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// TODO: make possible that we eliminate from fetch existing id
// to do this we need to save data into hashable map
const Cls = (window.Cache = class Cache {
  static initClass() {
    this.cache = {};
    this.cache_times = {};
  }

  static fetch(key, func){
    if (typeof(key) === "object") { key = JSON.stringify(key); }

    if (!this.cache[key]) {
      this.cache_times[key] = this.currTime();
      this.cache[key] = func.call();
    }
    return this.cache[key];
  }

  static hasKey(key) {
    if (typeof(key) === "object") { key = JSON.stringify(key); }
    return !!(this.cache[key] && (this.cache_times[key] > new Date()));
  }

  static set(key, data){
    if (typeof(key) === "object") { key = JSON.stringify(key); }
    this.cache_times[key] = this.currTime();
    return this.cache[key] = data;
  }
  static get(key){
    if (typeof(key) === "object") { key = JSON.stringify(key); }
    if (this.cache[key] && (this.cache_times[key] > new Date())) { return this.cache[key]; }
  }

  static deleteBy(key){
    for (let k in this.cache) {
      const v = this.cache[k];
      if (k.indexOf(key) > -1) { delete(this.cache[k]); }
    }
  }

  // will return 15 minutes later
  static currTime() {
    const time = new Date();
    return time.setMinutes(time.getMinutes() + 1);
  }

  static clear() {
    this.cache       = {};
    this.cache_times = {};
  }
});
Cls.initClass();