import I18n from './i18n.js'
import en from '@/../../config/locales/en-US.yml';
import et from '@/../../config/locales/et-EE.yml';
import ru from '@/../../config/locales/ru-RU.yml';
import lv from '@/../../config/locales/lv-LV.yml';

window.I18n = I18n
// I18n.translations = <%= I18n::JS.filtered_translations.slice(*Globalize::LOCALE_MAPPER.values.map(&:to_sym)).to_json %>;
// we should think if we want to pack all translations to 1 file
Object.assign(I18n.translations, en)
Object.assign(I18n.translations, et)
Object.assign(I18n.translations, ru)
Object.assign(I18n.translations, lv)
window.I18n.fallbacks = true
window.I18n.defaultLocale = "en-US"
