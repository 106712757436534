export default ({
  computed: {
    app(){ return this.$root},
    modal:{
      get(){return this.app.modalValue},
      set(v){return this.app.modalValue=v},
    },
    locale: {
      get(){ return this.app.localeValue},
      set(v){ this.app.localeValue = v},
    },
  },
  methods: {
    $translate (...args){ return this.translate(...args)},
    translate  (str, options={}){
      window.I18n.locale = this.locale
      return window.I18n.t(str, options)
    },
  },
})
