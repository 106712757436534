<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .header.clearfix
      h2
        .title
          | {{ translate('admin.media_library.delete.message' ) }}
    .footer.clearfix
      .buttons
        .right
          a.empty-button.close-reveal-modal( href="" @click="closeModal")
            | {{ translate('admin.buttons.cancel') }}
          button.button.primary.submit( type="submit" @click="onSubmit")
            | {{ translate('admin.buttons.delete') }}
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
export default{
  mixins: [Root],
  data() {
    return {onConfirm: null};
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      return this.onConfirm(() => this.closeModal());
    }
  }
}
</script>
