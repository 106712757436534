<template lang="pug">
.field-helper.select.fancy-select( @click="openDropDownMenu")
    .fake-input(data="fake-name")
      template( v-if="value")
        | {{translate('admin.product.shared.short_units.' + value, {defaultValue: value})}}
    ul.custom-dropdown( style="width: auto;")
      li( v-for="option in options" :class="{'active':(value === option)}" @click="value=option")
        | {{translate('admin.product.shared.units.' + option, {defaultValue: option})}}
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import variant_attribute from './variant_attribute';
import root from '../../../mixins/root_computed.js';
export default {
  mixins: [root,variant_attribute],
  props: ["variant", "name", "options"],
  computed: {
    value: {
      get() {
        return this.get_attribute(this.name) || this.set_attribute(this.name, this.options[0]);
      },
      set(value) {
        return this.set_attribute(this.name, value);
      }
    }
  }
}
</script>