<template lang="pug">
div
    .field.special-field.money
      helpers-label(field="variant.price")
      .field-container
        input(v-model="attribute" placeholder="0.00" @input="setPosition")
        .field-helper( v-text="currency(current_shop).code")
      helpers-validation-message.error(:errors="parent_validation" field="variant.price")
</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Cleave from 'cleave.js';
import Roots from '@/admin/mixins/root_computed.js';
import variant_attribute from './variant_attribute.js';
export default{
  mixins: [Roots,variant_attribute],
  attribute: "price",
  data() {
    return {
      cleave: new Cleave(document.createElement("input"), {
        numeral: true,
        delimiter: ".",
        numeralDecimalMark: "",
        numeralIntegerScale: 999,
        stripLeadingZeroes: true,
        numeralThousandsGroupStyle: 'none'
      }
      )
    };
  },

  created() {
    return this.attribute = this.process(this.attribute);
  },
  unmounted() {
    return this.cleave.destroy();
  },
  methods: {
    setPosition(e) {
      const values = [e.target.selectionStart, e.target.selectionEnd];
      this.$nextTick().then(() => {
        e.target.setSelectionRange(...values);
      });
    },
    process(value) {
      this.cleave.setRawValue(value || "");
      const formatted = this.cleave.getFormattedValue();
      if (formatted) {
        return formatted.toFixed(2);
      } else {
        return formatted;
      }
    }
  }
}
</script>
