export default {
  props: ["variant"],

  computed:{
    attribute: {
      get(){return this.get_attribute(this.$options.attribute)},

      set(value) {
        if (this.process) value = this.process(value) // escaping proxy
        this.set_attribute(this.$options.attribute, value)
        // this.$forceUpdate()
        return this.attribute
      }
    }
  },
  methods: {
    get_attribute(name){ return this.variant[name] },
    set_attribute(name, value) { return this.variant[name] = value },
    gset_attribute(name, value) { return this.get_attribute(name) || this.set_attribute(name, value)  },
  }
}